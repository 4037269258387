import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { environment } from '@nx-bundesliga/bundesliga-com/environment';
import { renewToken } from '@nx-bundesliga/bundesliga-com/framework/store-actions';
import { getWorkingProfile } from '@nx-bundesliga/bundesliga-com/framework/store-selectors';
import { ProfileUser, UserNewsletters, Language, ProfileAccount } from '@nx-bundesliga/models';
import { delay, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class OktaApiService {
	public settings: any;

	public httpHeadersWithoutAuthHeader = {
		headers: new HttpHeaders({
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		})
	};

	public oktaApiBaseUrl = `${environment.okta.oktaRestUrl}/api/v1`;
	public apiBaseUrl = environment.okta.restUrl;

	constructor(private http: HttpClient, private pstore: Store<ProfileUser>) {}

	public resetPassword(password: string, stateToken: string): Observable<any> {
		const restUrl = this.oktaApiBaseUrl + '/authn/credentials/reset_password';
		const passwordResetParams = {
			newPassword: password,
			stateToken: stateToken
		};
		return this.http.post<any>(restUrl, JSON.stringify(passwordResetParams), this.httpHeadersWithoutAuthHeader).pipe(
			map((transaction: any) => {
				if (transaction.status === 'SUCCESS') {
					return transaction.sessionToken;
				} else {
					throwError('Unknown error');
				}
			}),
			catchError(this.handleError)
		);
	}

	public changePassword(oldPassword: string, password: string): Observable<any> {
		const restUrl = `${this.apiBaseUrl}/changePassword`;
		const passwordResetParams = {
			'oldPassword': oldPassword,
			'newPassword': password
		};
		return this.pstore.pipe(
			select(getWorkingProfile),
			take(1),
			map((user) => `Bearer ${user?.tokens?.accessToken}`),
			switchMap((accessToken) => this.http.post(restUrl, passwordResetParams, { headers: { Authorization: accessToken } })),
			take(1),
			map((transaction: any) => {
				if (transaction.status === 'SUCCESS') {
					return transaction.sessionToken;
				} else {
					throwError('Unknown error');
				}
			}),
			catchError(this.handleError)
		);
	}

	public forgotPassword(email: string, captchaSolution: string): Observable<any> {
		const restUrl = `${this.apiBaseUrl}/forgotPassword`;
		const forgotPasswordEmail = { 'email': email };
		return this.http
			.post(restUrl, forgotPasswordEmail, {
				headers: {
					'X-DFL-CAPTCHA': captchaSolution
				}
			})
			.pipe(catchError(this.handleError));
	}

	public activateAccount(token: string): Observable<any> {
		const restUrl = `${this.apiBaseUrl}/activate`;
		const activationParams = { activationToken: token };
		return this.http.post<any>(restUrl, activationParams).pipe(catchError(this.handleError));
	}

	public activateEmailChange(verificationCode: string): Observable<any> {
		const restUrl = `${this.apiBaseUrl}/activateEmail`;
		return this.pstore.pipe(
			select(getWorkingProfile),
			take(1),
			map((user) => 'Bearer ' + user?.tokens?.accessToken),
			switchMap((accessToken) => this.http.post(restUrl, { verificationCode }, { headers: { Authorization: accessToken } })),
			catchError(this.handleError)
		);
	}

	public registerNewsletterSingle(newsletter: string, registration: { email: string; firstname?: string; lastname?: string; language: string; subscriptionSource: string }, captchaSolution: string): Observable<any> {
		const restUrl = `${this.apiBaseUrl}/newsletterSubscriptions/${newsletter}`;
		return this.http
			.post(restUrl, registration, {
				headers: {
					'X-DFL-CAPTCHA': captchaSolution
				}
			})
			.pipe(catchError(this.handleError));
	}

	public getNewsletters(): Observable<UserNewsletters> {
		const restUrl = `${this.apiBaseUrl}/newsletterSubscriptions`;
		return this.pstore.pipe(
			select(getWorkingProfile),
			take(1),
			map((user) => `Bearer ${user?.tokens?.accessToken}`),
			switchMap((accessToken) => this.http.get(restUrl, { headers: { Authorization: accessToken } })),
			take(1),
			catchError(this.handleError)
		);
	}

	public registerNewsletters(newsletters: UserNewsletters, subscriptionSource: string): Observable<any> {
		const restUrl = `${this.apiBaseUrl}/newsletterSubscriptions`;
		newsletters.subscriptionSource = subscriptionSource;
		return this.pstore.pipe(
			select(getWorkingProfile),
			take(1),
			map((user) => `Bearer ${user?.tokens?.accessToken}`),
			switchMap((accessToken) => this.http.post(restUrl, newsletters, { headers: { Authorization: accessToken } })),
			take(1),
			map((transaction: any) => {
				if (transaction.status === 'SUCCESS') {
					return true;
				} else {
					throwError('Unknown error');
				}
			}),
			catchError(this.handleError)
		);
	}

	public getPasswordPolicy(): Observable<any> {
		const restUrl = `${this.apiBaseUrl}/passwordPolicy`;
		return this.http.get(restUrl);
	}

	private createUserData(formData: any) {
		if (formData.preferences.tos === false) {
			throw new Error('You have to accept Terms and Conditions');
		}
		return {
			'product': formData.product,
			'profile': {
				'email': formData.email,
				'login': formData.email,
				'firstName': formData.profile.firstName,
				'lastName': formData.profile.lastName,
				'DFL_gender': formData.profile.gender,
				'countryCode': formData.profile.country,
				'locale': formData.locale,
				'DFL_birthdate': formData.profile.birthDate,
				'DFL_favoriteTeams': formData.favoriteTeams,
				'DFL_registrationSource': formData.product,
				'DFL_newsletterBundesligaSubscribed': formData.newsletter.bundesligaNewsletter,
				'DFL_newsletterPartnerSubscribed': formData.newsletter.partnerNewsletter,
				...formData.custom
			},
			...(formData.hasOwnProperty('password') &&
				formData.password && {
					'credentials': {
						'password': {
							'value': formData.password
						}
					}
				})
		};
	}

	public registerUser(formData: any, captchaSolution: string): Observable<any> {
		const userProfile = this.createUserData(formData);
		const restUrl = `${this.apiBaseUrl}/register`;
		return this.http
			.post(restUrl, userProfile, {
				headers: {
					'X-DFL-CAPTCHA': captchaSolution
				}
			})
			.pipe(catchError(this.handleError));
	}

	public updateRegistration(formData: any): Observable<any> {
		const userProfile = this.createUserData(formData);
		const restUrl = `${this.apiBaseUrl}/registerSocial`;
		return this.pstore.pipe(
			select(getWorkingProfile),
			take(1),
			map((user) => `Bearer ${user?.tokens?.accessToken}`),
			switchMap((accessToken) => this.http.post(restUrl, userProfile, { headers: { Authorization: accessToken } })),
			take(1),
			delay(500),
			tap(() => this.pstore.dispatch(renewToken())),
			delay(1000),
			catchError(this.handleError)
		);
	}

	public updateProfile(formData: any): Observable<any> {
		const userProfile = {
			profile: {
				'email': formData.email,
				'firstName': formData.profile.firstName,
				'lastName': formData.profile.lastName,
				'DFL_gender': formData.profile.gender,
				'countryCode': formData.profile.country,
				'locale': formData.locale,
				'DFL_birthdate': formData.profile.birthDate,
				'DFL_favoriteTeams': formData.favoriteTeams,
				'DFL_crossProductProfileBuildingOptout': formData?.crossAccountProfileOptout === true ? true : false
			}
		};
		const restUrl = `${this.apiBaseUrl}/profile`;
		return this.pstore.pipe(
			select(getWorkingProfile),
			take(1),
			map((user) => `Bearer ${user?.tokens?.accessToken}`),
			switchMap((accessToken) => this.http.post(restUrl, userProfile, { headers: { Authorization: accessToken } })),
			take(1),
			catchError(this.handleError)
		);
	}

	public addUserToGroup(groupId: string): Observable<any> {
		const requestBody = {
			groupId: groupId
		};
		const restUrl = `${this.apiBaseUrl}/group/addUser`;
		return this.pstore.pipe(
			select(getWorkingProfile),
			take(1),
			map((user) => `Bearer ${user?.tokens?.accessToken}`),
			switchMap((accessToken) => this.http.put(restUrl, requestBody, { headers: { Authorization: accessToken } })),
			take(1),
			catchError(this.handleError)
		);
	}

	public deleteAccount(): Observable<any> {
		const restUrl = `${this.apiBaseUrl}/profile/deactivate`;
		return this.pstore.pipe(
			select(getWorkingProfile),
			take(1),
			map((user) => 'Bearer ' + user?.tokens?.accessToken),
			switchMap((accessToken) => this.http.post(restUrl, '', { headers: { Authorization: accessToken } })),
			map((transaction: any) => {
				if (transaction.status === 'SUCCESS') {
					return true;
				} else {
					throwError('Unknown error');
				}
			}),
			catchError(this.handleError)
		);
	}

	public changeEmail(email: string): Observable<any> {
		const restUrl = `${this.apiBaseUrl}/changeEmail`;
		return this.pstore.pipe(
			select(getWorkingProfile),
			take(1),
			map((user) => 'Bearer ' + user?.tokens?.accessToken),
			switchMap((accessToken) => this.http.post(restUrl, { email }, { headers: { Authorization: accessToken } })),
			catchError(this.handleError)
		);
	}

	private handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('An error occurred:', error.error.message);
			return throwError('Connection error');
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong.
			return throwError(error.error || error);
		}
	}

	public getLanguageCodeForCountryList(langState: Language): string {
		if (langState.code === 'de' || langState.code === 'fr' || langState.code === 'es' || langState.code === 'es') {
			return langState.code;
		} else {
			return 'en';
		}
	}

	public mapSocialProvider(profile: ProfileAccount): string {
		if (profile.socialProvider?.apple) {
			return 'Apple SSO';
		}
		if (profile.socialProvider?.facebook) {
			return 'Facebook SSO';
		}
		if (profile.socialProvider?.google) {
			return 'Google SSO';
		}
		return 'E-Mail';
	}
}
