// libs
import { createAction, props } from '@ngrx/store';
import { Profile, ProfileTokens } from '@nx-bundesliga/models';

export const profileInit = createAction('[PROFILE] INIT');
export const getProfile = createAction('[PROFILE] GETPROFILE', (defaultPayload: { newsletter: boolean; profile: boolean } = { profile: true, newsletter: true }) => ({
	payload: defaultPayload
}));
export const setProfile = createAction('[PROFILE] SETPROFILE', props<Profile & { isVerified?: boolean; registrationComplete?: boolean }>());
export const setProfileError = createAction('[PROFILE] SETPROFILEERROR', props<any>());
export const login = createAction('[PROFILE] LOGIN', props<{ tokens: ProfileTokens; claims: any }>());
export const renewToken = createAction('[PROFILE] RENEWTOKEN');
export const logout = createAction('[PROFILE] LOGOUT');
export const logoutSilent = createAction('[PROFILE] LOGOUTSILENT');
